/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import logoimages from "../../images/logo_1.png";
import Logo1 from "../../images/Screenshot_1.png";
import moment from "moment";
import "moment-timezone";
import "./Style.css";
import Clear from "@material-ui/icons/Clear";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { KeyboardArrowDown } from "@material-ui/icons";

import { Stack } from "@mui/material";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/features/userSlice";

import {
  createTournament,
  getLeagueCategories,
  fetchRegionalTourList,
  fetchRoundOptions,
  fetchPlayers,
  tournamentExists,
  fetchTournamentList,
  userLogout,
  createMultiSourceTour,
  externalCall,
  fetchSourceTourPlayers,
} from "../../api";
import { showToast } from "../toast";

const CreateTournament = () => {
  const dispatch = useDispatch();

  let [PlayerList, setPlayerList] = useState(false);
  let [tournamentList, setTournamentList] = useState(true);
  let [tourSelectionList, setTourSelectionList] = useState(false);

  const [loading, setLoading] = useState(false);

  // <!-- New Login to create tournament -->
  const history = useHistory();
  const [regionOptions, setRegionOptions] = useState([]);
  const [tourna, setTourna] = useState("");
  const [tournaList, setTournaList] = useState([]);
  const [roundOptions, setRoundOptions] = useState([]);
  const [sourceTourRoundOptions, setSourceTourRoundOptions] = useState([]);
  const [sourceTourOptions, setSourceTourOptions] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPlayers, setTotalPlayers] = useState("");

  const [formData, setFormData] = useState({
    region: "",
    tournamentmain: "",
    Round1: "",
    Round1startdate: null,
    Round1enddate: null,
    Round1status: 0,
    Round2: "",
    Round2startdate: null,
    Round2enddate: null,
    Round2status: 0,
    Round3: "",
    Round3startdate: null,
    Round3enddate: null,
    Round3status: 0,
    NumberOfPlayers: 0,
    Pages: 0,
    beutifiedName: "",
  });

  const [multiTourFormData, setMultiFormData] = useState({
    mainTournament: {
      region: "",
      tour_name: "",
      Round1: "",
      Round1startdate: null,
      Round1enddate: null,
      Round1status: 0,
      Round2: "",
      Round2startdate: null,
      Round2enddate: null,
      Round2status: 0,
      Round3: "",
      Round3startdate: null,
      Round3enddate: null,
      Round3status: 0,
      NumberOfPlayers: 0,
      Pages: 0,
      beutifiedName: "",
      isMultiSource: true,
      isCumulative: true,
    },
    sourceTournaments: [
      {
        id: 1,
        region: "",
        tour_name: "",
        round: "",
        NumberOfPlayers: 0,
        beutifiedName: "",
      },
    ],
  });

  const [errors, setErrors] = useState(null);

  const handleFormChange = async (key, val) => {
    if (key === "tournamentmain") {
      await setFormData((prev) => ({
        ...prev,
        tournamentmain: val.value,
        beutifiedName: val.label,
      }));
      return;
    }
    if (key === "Round1") {
      // finding pattern
      const selectedRoundIndex = roundOptions.findIndex(
        (round) => round.value == val.value
      );
      if (selectedRoundIndex !== -1) {
        if (roundOptions[selectedRoundIndex + 1]) {
          const buttons = document.getElementsByClassName("selecttourbutton");
          if (buttons.length > 0) {
            buttons[0].disabled = false;
          }
          const nextRound = roundOptions.slice(
            selectedRoundIndex + 1,
            roundOptions.length
          );

          let roundMiddlePart = nextRound[0].value.split("_");

          // Get the middle part
          let middlePart = roundMiddlePart.slice(1, -1).join(" ");

          await setFormData((prev) => ({
            ...prev,
            Round1: val.value,
            Round1startdate: moment(val.beginTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            Round1enddate: moment(val.endTime).format("YYYY-MM-DD HH:mm:ss"),
            Round2: nextRound[0].value,
            Round2startdate: moment(nextRound[0].beginTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            Round2enddate: moment(nextRound[0].endTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            beutifiedName: prev.beutifiedName + "-" + middlePart,
            // Round3: nextRound[1] ? nextRound[1].value : "",
            // Round3startdate: nextRound[1] ? moment(nextRound[1].beginTime).format('YYYY-MM-DD HH:mm:ss') : "",
            // Round3enddate: nextRound[1] ? moment(nextRound[1].endTime).format('YYYY-MM-DD HH:mm:ss') : "",
          }));
        } else {
          console.log("Next Round is not available");
          const buttons = document.getElementsByClassName("selecttourbutton");
          if (buttons.length > 0) {
            buttons[0].disabled = true;
          }
          return;
        }
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: val,
      }));
    }
  };

  // add multiple source tournament
  const addSourceTournament = () => {
    setMultiFormData((prevState) => ({
      ...prevState,
      sourceTournaments: [
        ...prevState.sourceTournaments,
        {
          id: prevState.sourceTournaments.length + 1,
          region: "",
          tour_name: "",
          round: "",
          NumberOfPlayers: 0,
          beutifiedName: "",
        },
      ],
    }));
  };

  // remove source tournament
  const removeSourceTournament = (indexToRemove) => {
    setMultiFormData((prevState) => ({
      ...prevState,
      sourceTournaments: prevState.sourceTournaments.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleRounds = (tournament, val, source) => {
    const selectedRoundIndex = roundOptions.findIndex(
      (round) => round.value == val.value
    );
    if (selectedRoundIndex !== -1) {
      if (roundOptions[selectedRoundIndex + 1]) {
        const buttons = document.getElementsByClassName("selecttourbutton");
        if (buttons.length > 0) {
          buttons[0].disabled = false;
        }
        const nextRound = roundOptions.slice(
          selectedRoundIndex + 1,
          roundOptions.length
        );
        return {
          Round1: val.value,
          Round1startdate: moment
            .utc(val.beginTime)
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          Round1enddate: moment
            .utc(val.endTime)
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          Round2: nextRound[0].value,
          Round2startdate: moment
            .utc(nextRound[0].beginTime)
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          Round2enddate: moment
            .utc(nextRound[0].endTime)
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          Round3: nextRound[1] ? nextRound[1].value : "",
          Round3startdate: nextRound[1]
            ? moment
                .utc(nextRound[1].beginTime)
                .format("YYYY-MM-DDTHH:mm:ss[Z]")
            : null,
          Round3enddate: nextRound[1]
            ? moment.utc(nextRound[1].endTime).format("YYYY-MM-DDTHH:mm:ss[Z]")
            : null,
        };
      } else {
        console.log("Next Round is not available");
        const buttons = document.getElementsByClassName("selecttourbutton");
        if (buttons.length > 0) {
          buttons[1].disabled = true;
        }
      }
    }
  };

  // handle multiTourForm
  const handleMultiTourFormData = (source, key, val, index = null) => {
    setMultiFormData((prevState) => {
      if (source === "mainTournament") {
        if (key === "tour_name") {
          return {
            ...prevState,
            mainTournament: {
              ...prevState.mainTournament,
              tour_name: val.value,
              beutifiedName: val.label,
            },
          };
        }
        if (key === "Round1") {
          const updatedRound = handleRounds(
            prevState.mainTournament,
            val,
            source
          );
          if (updatedRound) {
            return {
              ...prevState,
              mainTournament: {
                ...prevState.mainTournament,
                ...updatedRound,
              },
            };
          }
        } else {
          return {
            ...prevState,
            mainTournament: {
              ...prevState.mainTournament,
              [key]: val,
            },
          };
        }
      }

      if (source === "sourceTournaments") {
        if (key === "tour_name") {
          return {
            ...prevState,
            sourceTournaments: prevState.sourceTournaments.map(
              (tournament, i) =>
                i === index
                  ? {
                      ...tournament,
                      tour_name: val.value,
                      beutifiedName: val.label,
                    }
                  : tournament
            ),
          };
        } else {
          return {
            ...prevState,
            sourceTournaments: prevState.sourceTournaments.map(
              (tournament, i) =>
                i === index ? { ...tournament, [key]: val } : tournament
            ),
          };
        }
      }

      return prevState;
    });
  };

  const createMultiSourceTournament = async () => {
    let tempErrors = {};

    if (multiTourFormData.mainTournament.region.trim().length === 0) {
      tempErrors["mainTourRegion"] = "Please Select Region";
    }
    if (multiTourFormData.mainTournament.tour_name.trim().length === 0) {
      tempErrors["mainTourName"] = "Please Select Tournament";
    }

    if (multiTourFormData.mainTournament.Round1.trim().length === 0) {
      tempErrors["mainTourRound1"] = "Please Select Round ";
    }

    // if (multiTourFormData.targetTournament.region.trim().length === 0) {
    //   tempErrors['targetTourRegion'] = "Please select target tournament region"
    // }

    // if (multiTourFormData.targetTournament.tour_name.trim().length === 0) {
    //   tempErrors['targetTourName'] = "Please select target tournament"
    // }

    // if (multiTourFormData.targetTournament.Round1.trim().length === 0) {
    //   tempErrors['targetTourRound1'] = 'Please select round 1'
    // }

    const errorsPromise = await Promise.all(
      multiTourFormData.sourceTournaments.map((source, index) => {
        if (source.region.trim().length === 0) {
          tempErrors[`source${index}region`] = "Please select region";
        }

        if (source.tour_name.trim().length === 0) {
          tempErrors[`source${index}tourName`] = "Please select tournament";
        }

        if (source.round.trim().length === 0) {
          tempErrors[`source${index}round`] = "Please select round";
        }

        if (source.NumberOfPlayers === 0) {
          tempErrors[`source${index}NoPlayers`] =
            "Number of players can not be 0";
        }

        if (source.NumberOfPlayers > 100) {
          tempErrors[`source${index}NoPlayers`] =
            "Maximum 100 players are allowed";
        }
      })
    );

    setErrors(tempErrors);

    // if (Object.keys(tempErrors).length === 0) {
    //   setLoading(true);
    //   const response = await createMultiSourceTour(multiTourFormData);
    //   if (response.status === 200) {
    //     showToast(response.data.message, 200);

    //     setTimeout(async () => {
    //       await externalCall();
    //     }, 500);
    //   }
    //   if (response.status >= 400 && response.status <= 500) {
    //     showToast("Something went wrong", response.status);
    //   }
    //   setLoading(false);

    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // }

    // new logic to allow admin to select players from multiple tournaments
    if (Object.keys(tempErrors).length === 0) {
      if (multiTourFormData.sourceTournaments.length > 0) {
        history.push({
          pathname: "/player-selection",
          state: {
            multiSourceData: multiTourFormData || null,
            isCumulative: true,
          },
        });
      }
    }
  };

  //fetch tournament list
  useEffect(() => {
    async function tournamentList() {
      const response = await fetchTournamentList();
      if (response?.data?.tournaments !== null) {
        setTournaments(response.data.tournaments);
      }
    }
    tournamentList();
  }, []);

  // fetch regions
  useEffect(() => {
    async function getLeagueCats() {
      const response = await getLeagueCategories();
      if (response.status === 200) {
        setRegionOptions(response.data.data);
      }

      if (response.status === 400) {
        setErrors((prev) => ({
          ...prev,
          region: "No regions found",
        }));
      }
    }
    getLeagueCats();
  }, []);

  // fetch tournament list of selected region
  useEffect(() => {
    async function fetchRegionalTournamentList() {
      setTourna("");
      setTournaList([]);
      let response;
      const region =
        formData.region.trim().length !== 0
          ? formData.region
          : multiTourFormData.mainTournament.region.trim().length !== 0
          ? multiTourFormData.mainTournament.region
          : "";
      response = await fetchRegionalTourList({ region: region });
      if (response.status === 200) {
        if (response?.data?.length > 0) {
          setTournaList(response.data);
        }
      }

      if (response.status === 400 && response.data.additionalData.noTourFound) {
        setErrors((prev) => ({
          ...prev,
          tournament: response.data.message,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          tournament: "",
        }));
      }
    }
    fetchRegionalTournamentList();
  }, [formData?.region, multiTourFormData?.mainTournament?.region]);

  // fetch rounds of a tournament
  useEffect(() => {
    async function fetchRounds() {
      let response;
      const tournament =
        formData.tournamentmain && formData.region
          ? { region: formData.region, tournamentmain: formData.tournamentmain }
          : {
              region: multiTourFormData.mainTournament.region,
              tournamentmain: multiTourFormData.mainTournament.tour_name,
            };
      response = await fetchRoundOptions(tournament);
      if (response.status === 200) {
        if (response?.data?.length > 0) {
          setRoundOptions(response.data);
        }
      }

      if (
        response.status === 400 &&
        response.data.additionalData.noRoundsFound
      ) {
        setErrors((prev) => ({
          ...prev,
          round1: response.data.message,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          round1: "",
        }));
      }
    }
    fetchRounds();
  }, [formData.tournamentmain, multiTourFormData?.mainTournament?.tour_name]);

  //fetch region tournaments for sourceTournamens
  async function fetchSourceTours(region) {
    const response = await fetchRegionalTourList({ region: region });
    if (response.status === 200) {
      if (response?.data?.length > 0) {
        setSourceTourOptions(response.data);
      }
    }
  }

  // fetch rounds for sourceTournaments
  async function fetchSourceTourRounds(region, tournament) {
    const tourInfo = { region: region, tournamentmain: tournament };

    const response = await fetchRoundOptions(tourInfo);
    if (response.status === 200) {
      if (response?.data?.length > 0) {
        setSourceTourRoundOptions(response.data);
      }
    }
  }

  const createTournamentAndPlayer = async () => {
    // handle errors
    const tempErrors = {};
    if (formData.region.trim().length === 0) {
      tempErrors["region"] = "Please Select Region";
    }

    if (formData.tournamentmain.trim().length === 0) {
      tempErrors["tournament"] = "Please Select Tournament";
    }

    if (formData.Round1.trim().length === 0) {
      tempErrors["round1"] = "Please Select Round";
    }

    if (formData.NumberOfPlayers === 0) {
      tempErrors["NumberOfPlayers"] = "Please Provide Number of Players";
    }

    setErrors(tempErrors);

    // if (Object.keys(tempErrors).length === 0) {
    //   setLoading(true);

    //   // create tournament
    //   const response = await createTournament(formData);

    //   if (response.status === 200) {
    //     showToast(response.data.message, response.status);

    //     setTimeout(async () => {
    //       await externalCall();
    //     }, 500);
    //   }
    //   if (
    //     response.status === 400 &&
    //     response?.data?.additionalData?.alreadyExists
    //   ) {
    //     showToast(response?.data?.message, response.status);
    //   }

    //   // store players
    //   const result = await fetchPlayers(formData);
    //   if (result?.data.additionalData?.noPlayersFound) {
    //     showToast(result?.data?.message, result.status);
    //   }

    //   setTimeout(() => {
    //     window.location.reload();
    //     setLoading(false);
    //   }, 1000);
    // }

    if (Object.keys(tempErrors).length === 0) {
      let sourceTournaments = [
        {
          NumberOfPlayers: formData.NumberOfPlayers,
          beutifiedName: formData.beutifiedName,
          id: 1,
          region: formData.region,
          round: formData.Round1,
          tour_name: formData.tournamentmain,
        },
      ];
      history.push({
        pathname: "player-selection",
        state: {
          formData: { formData, sourceTournaments },
          isCumulative: false,
        },
      });
      return;
    }
  };

  // update total number of players in case of multi source tournament
  useEffect(() => {
    function calculateTotalPlayers() {
      const total = multiTourFormData.sourceTournaments.reduce(
        (acc, source) => {
          return acc + (Number(source.NumberOfPlayers) || 0);
        },
        0
      );

      setTotalPlayers(total);
    }

    calculateTotalPlayers();
  }, [multiTourFormData.sourceTournaments]);

  // <!-- New Login to create tournamen -->

  const handleLogout = async () => {
    const response = await userLogout();

    if (response?.status === 200) {
      dispatch(logoutUser());
      showToast(response?.data?.message, response?.status);
      history.push({
        pathname: "/login",
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const tourList = () => {
    setTournamentList(true);
    setTourSelectionList(false);
    setPlayerList(false);
  };

  const playerList = () => {
    setTourSelectionList(false);
    setPlayerList(true);
    setTournamentList(false);
  };

  const [activeTab, setActiveTab] = useState("active");

  const renderContent = () => {
    switch (activeTab) {
      // Normal Tournament
      case "active":
        return (
          <div className="d-flex flex-column gap-3 landingscreen px-4">
            <div className="text-center">
              <Select
                defaultValue={formData.region || ""}
                onChange={(e) => handleFormChange("region", e.label)}
                options={regionOptions}
                placeholder="Select Region"
              />
              {errors && errors.region ? (
                <span className="text-danger">{errors.region}</span>
              ) : (
                ""
              )}
            </div>

            <div className="text-center">
              <Select
                defaultValue={tourna}
                onChange={(e) => handleFormChange("tournamentmain", e)}
                options={tournaList}
                placeholder="Select Tournament"
              />
              {errors && errors.tournament ? (
                <span className="text-danger">{errors.tournament}</span>
              ) : (
                ""
              )}
            </div>

            <div className="text-center">
              <Select
                defaultValue={(formData && formData.Round1) || ""}
                onChange={(e) => handleFormChange("Round1", e)}
                options={roundOptions}
                placeholder="Select Round"
              />

              {errors && errors.round1 ? (
                <span className="text-danger">{errors.round1}</span>
              ) : (
                ""
              )}

              {formData.Round1startdate && formData.Round1enddate ? (
                <>
                  <div
                    className="alert alert-primary mt-2 p-1 text-center"
                    style={{ width: "400px", margin: "auto" }}
                    role="alert"
                  >
                    Begin Time:-{formData.Round1startdate || ""}
                  </div>
                  <div
                    className="alert alert-danger mt-2 p-1 text-center"
                    style={{ width: "400px", margin: "auto" }}
                    role="alert"
                  >
                    End Time:-{formData.Round1enddate || ""}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className=" text-center">
              <div className="mt-4 d-flex gap-2 justify-content-center align-items-center">
                <span htmlFor="player">Number of Players</span>
                <input
                  type="number"
                  id="player"
                  className="form-control text-center"
                  value={formData.NumberOfPlayers || 0}
                  onChange={(e) =>
                    handleFormChange("NumberOfPlayers", e.target.value)
                  }
                  style={{ width: "100px", height: "40px" }}
                />
              </div>
              {errors && errors.NumberOfPlayers ? (
                <span className="text-danger">{errors.NumberOfPlayers}</span>
              ) : (
                ""
              )}
              <br />
              <button
                className="selecttourbutton my-2"
                onClick={createTournamentAndPlayer}
              >
                Activate Tournament
              </button>
            </div>
          </div>
        );

      // Multi source Tournament
      case "link":
        return (
          <div className="">
            {/* Main tournament form */}
            <fieldset className="p-2">
              <legend>
                <span className="mx-2 fs-6">Main Tournament</span>
              </legend>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <Select
                    defaultValue={multiTourFormData.mainTournament.region || ""}
                    onChange={(e) =>
                      handleMultiTourFormData(
                        "mainTournament",
                        "region",
                        e.label
                      )
                    }
                    options={regionOptions}
                    placeholder="Select Region"
                  />
                  {errors && errors.mainTourRegion ? (
                    <span className="text-danger">{errors.mainTourRegion}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex flex-column">
                  <Select
                    defaultValue={multiTourFormData.mainTournament.tour_name}
                    onChange={(e) =>
                      handleMultiTourFormData("mainTournament", "tour_name", e)
                    }
                    options={tournaList}
                    placeholder="Select Tournament"
                  />
                  {errors && errors.mainTourName ? (
                    <span className="text-danger">{errors.mainTourName}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex flex-column">
                  <Select
                    defaultValue={
                      multiTourFormData &&
                      multiTourFormData.mainTournament.Round1
                    }
                    onChange={(e) =>
                      handleMultiTourFormData("mainTournament", "Round1", e)
                    }
                    options={roundOptions}
                    placeholder="Select Round 1"
                  />

                  {errors && errors.mainTourRound1 ? (
                    <span className="text-danger">{errors.mainTourRound1}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex align-align-items-center justify-content-center">
                  <label
                    className="container text-start text-nowrap"
                    htmlFor="totalPlayers"
                  >
                    Total Players
                  </label>
                  <input
                    type="number"
                    id="totalPlayers"
                    className="form-control"
                    value={totalPlayers && totalPlayers}
                    disabled
                  />
                </div>
              </div>
            </fieldset>

            {/* Source Tournaments */}
            <fieldset className="border p-2">
              <legend className="d-flex flex-wrap justify-content-between align-items-center">
                <span className="fs-6 mx-2">Source Tournaments</span>
              </legend>
              <div className="d-flex flex-column">
                {multiTourFormData.sourceTournaments.map(
                  (sourceTournament, index) => (
                    <div
                      key={sourceTournament.id}
                      className="container-lg d-flex flex-column gap-3 border-b-2 p-2"
                    >
                      <h6>Source Tournament {index + 1}</h6>

                      {/* Source Tournament region */}
                      <div className="row d-flex flex-col gap-3">
                        <div
                          className="col-12 col-md-12"
                          style={{ height: "fit-content" }}
                        >
                          <Select
                            defaultValue={
                              multiTourFormData &&
                              multiTourFormData.sourceTournaments[index].region
                            }
                            onChange={async (e) => {
                              await fetchSourceTours(e.label);
                              await handleMultiTourFormData(
                                "sourceTournaments",
                                "region",
                                e.label,
                                index
                              );
                            }}
                            options={regionOptions}
                            placeholder="Select Region"
                          />
                          {errors && errors[`source${index}region`] ? (
                            <span className="text-danger">
                              {errors[`source${index}region`]}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* Source Tournament name */}
                        <div
                          className="col-12 col-md-12"
                          style={{ height: "fit-content" }}
                        >
                          <Select
                            defaultValue={tourna}
                            onChange={async (e) => {
                              fetchSourceTourRounds(
                                multiTourFormData.sourceTournaments[index]
                                  .region,
                                e.value
                              );
                              await Promise.all(
                                handleMultiTourFormData(
                                  "sourceTournaments",
                                  "tour_name",
                                  e,
                                  index
                                )
                              );
                            }}
                            options={sourceTourOptions}
                            placeholder="Select Tournament"
                          />
                          {errors && errors[`source${index}tourName`] ? (
                            <span className="text-danger">
                              {errors[`source${index}tourName`]}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* Source Tournament Round */}
                        <div
                          className="col-12 col-md-12"
                          style={{ height: "fit-content" }}
                        >
                          <Select
                            defaultValue={
                              multiTourFormData &&
                              multiTourFormData.sourceTournaments[index].round
                            }
                            onChange={(e) =>
                              handleMultiTourFormData(
                                "sourceTournaments",
                                "round",
                                e.value,
                                index
                              )
                            }
                            options={sourceTourRoundOptions}
                            placeholder="Select Round 1"
                          />
                        </div>

                        {/* Source Tournament Number of players */}
                        <div
                          className="col-12 col-md-12"
                          style={{ height: "fit-content" }}
                        >
                          <input
                            type="number"
                            id={`sourceTournament${index}NoOfPlayers`}
                            className="form-control"
                            value={
                              sourceTournament.NumberOfPlayers === 0
                                ? ""
                                : sourceTournament.NumberOfPlayers
                            }
                            onChange={(e) =>
                              handleMultiTourFormData(
                                "sourceTournaments",
                                "NumberOfPlayers",
                                e.target.value,
                                index
                              )
                            }
                            placeholder="Number of players"
                          />
                          {errors && errors[`source${index}NoPlayers`] ? (
                            <span className="text-danger">
                              {errors[`source${index}NoPlayers`]}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className="col-12 col-md-2"
                          style={{ height: "fit-content" }}
                        >
                          <Clear
                            className={
                              index === 0 ? "d-none" : "cursor-pointer d-block"
                            }
                            onClick={() => removeSourceTournament(index)}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="text-end">
                <button
                  onClick={addSourceTournament}
                  className="sorce-tour-btn"
                >
                  Add Tournament
                </button>
              </div>
            </fieldset>

            <div className="text-md-center text-center m-2">
              <button
                onClick={createMultiSourceTournament}
                className="selecttourbutton"
              >
                Activate Tournament
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <div className="contenorlanding">
            <div className="contentrow col-12">
              <div className="nevcolumn col-2">
                <div className="nevlogo">
                  <img className="photoimagelanding" src={Logo1} alt="logo" />
                </div>

                <div className="nevlistst">
                  <li className="listnevbars">
                    <Link
                      to="/players-list"
                      className="text-decoration-none text-black"
                      onClick={playerList}
                    >
                      Player List
                    </Link>
                  </li>

                  {/* <li className="listnevbars">
                    <Link
                      to="/tournament-list"
                      className="text-decoration-none text-black"
                      onClick={tourList}
                    >
                      Tournament List
                    </Link>
                  </li> */}

                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "16px", pointerEvents: 'none' }}
                          className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Tournament
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion show collapse px-2"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/tournament-list"
                            className="text-decoration-none text-black px-2"
                          >
                            <span style={{ fontSize: "14px" }}>List</span>
                          </Link>
                        </div>
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/create-tournament"
                            className="text-decoration-none text-black text-sm px-2"
                          >
                            <span style={{ fontSize: "14px" }}>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>

              <div
                className="col-10 screensection "
                style={{ height: "auto", background: "#F8F8F8" }}
              >
                <div className="landingRow col-12 mb-4">
                  <div className="nevbarbutton p-0">
                    <button
                      className="nevbarlogbutton m-0"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className="landingsscreens">
                  <div className="landing-box col-12 d-flex flex-column gap-2 pt-3">
                    {/* Tabs */}
                    <ul className="nav nav-pills  d-flex flex-row justify-content-center align-items-center">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            activeTab === "active" ? "active-btn" : ""
                          }`}
                          aria-current="page"
                          onClick={() => setActiveTab("active")}
                        >
                          Normal
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            activeTab === "link" ? "active-btn" : ""
                          }`}
                          onClick={() => setActiveTab("link")}
                        >
                          Cumulative
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content mt-3">{renderContent()}</div>
                    {/* To create tournament and select players from same round */}
                    {/* <div className="d-flex flex-column gap-3 landingscreen px-4">
                      <div className="text-center">
                        <Select
                          defaultValue={formData.region || ""}
                          onChange={(e) => handleFormChange("region", e.label)}
                          options={regionOptions}
                          placeholder="Select Region"
                        />
                        {errors && errors.region ? (
                          <span className="text-danger">{errors.region}</span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="text-center">
                        <Select
                          defaultValue={tourna}
                          onChange={(e) =>
                            handleFormChange("tournamentmain", e)
                          }
                          options={tournaList}
                          placeholder="Select Tournament"
                        />
                        {errors && errors.tournament ? (
                          <span className="text-danger">
                            {errors.tournament}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="text-center">
                        <Select
                          defaultValue={(formData && formData.Round1) || ""}
                          onChange={(e) => handleFormChange("Round1", e)}
                          options={roundOptions}
                          placeholder="Select Round"
                        />

                        {errors && errors.round1 ? (
                          <span className="text-danger">{errors.round1}</span>
                        ) : (
                          ""
                        )}

                        {formData.Round1startdate && formData.Round1enddate ? (
                          <>
                            <div
                              className="alert alert-primary mt-2 p-1 text-center"
                              style={{ width: "400px", margin: "auto" }}
                              role="alert"
                            >
                              Begin Time:-{formData.Round1startdate || ""}
                            </div>
                            <div
                              className="alert alert-danger mt-2 p-1 text-center"
                              style={{ width: "400px", margin: "auto" }}
                              role="alert"
                            >
                              End Time:-{formData.Round1enddate || ""}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className=" text-center">
                        <div className="mt-4 d-flex gap-2 justify-content-center align-items-center">
                          <span htmlFor="player">Number of Players</span>
                          <input
                            type="number"
                            id="player"
                            className="form-control text-center"
                            value={formData.NumberOfPlayers || 0}
                            onChange={(e) =>
                              handleFormChange(
                                "NumberOfPlayers",
                                e.target.value
                              )
                            }
                            style={{ width: "100px", height: "40px" }}
                          />
                        </div>
                        {errors && errors.NumberOfPlayers ? (
                          <span className="text-danger">
                            {errors.NumberOfPlayers}
                          </span>
                        ) : (
                          ""
                        )}
                        <br />
                        <button
                          className="selecttourbutton my-2"
                          onClick={createTournamentAndPlayer}
                        >
                          Activate Tournament
                        </button>
                      </div>
                    </div> */}

                    {/* To create tournament and select players from different round */}
                    <div className="displayNone">
                      {/* <fieldset className="border p-2">
                        <legend>
                          <span className="mx-2 fs-6">Main Tournament</span>
                        </legend>
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex flex-column">
                            <Select
                              defaultValue={
                                multiTourFormData.mainTournament.region || ""
                              }
                              onChange={(e) =>
                                handleMultiTourFormData(
                                  "mainTournament",
                                  "region",
                                  e.label
                                )
                              }
                              options={regionOptions}
                              placeholder="Select Region"
                            />
                            {errors && errors.mainTourRegion ? (
                              <span className="text-danger">
                                {errors.mainTourRegion}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <Select
                              defaultValue={
                                multiTourFormData.mainTournament.tour_name
                              }
                              onChange={(e) =>
                                handleMultiTourFormData(
                                  "mainTournament",
                                  "tour_name",
                                  e
                                )
                              }
                              options={tournaList}
                              placeholder="Select Tournament"
                            />
                            {errors && errors.mainTourName ? (
                              <span className="text-danger">
                                {errors.mainTourName}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <Select
                              defaultValue={
                                multiTourFormData &&
                                multiTourFormData.mainTournament.Round1
                              }
                              onChange={(e) =>
                                handleMultiTourFormData(
                                  "mainTournament",
                                  "Round1",
                                  e
                                )
                              }
                              options={roundOptions}
                              placeholder="Select Round 1"
                            />

                            {errors && errors.mainTourRound1 ? (
                              <span className="text-danger">
                                {errors.mainTourRound1}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex align-align-items-center justify-content-center">
                            <label
                              className="container text-start text-nowrap"
                              htmlFor="totalPlayers"
                            >
                              Total Players
                            </label>
                            <input
                              type="number"
                              id="totalPlayers"
                              className="form-control"
                              value={totalPlayers && totalPlayers}
                              disabled
                            />
                          </div>
                        </div>
                      </fieldset> */}

                      {/* Source Tournaments */}
                      {/* <fieldset className="border p-2">
                        <legend className="d-flex flex-wrap justify-content-between align-items-center">
                          <span className="fs-6 mx-2">Source Tournaments</span>
                        </legend>
                        <div className="d-flex flex-column">
                          {multiTourFormData.sourceTournaments.map(
                            (sourceTournament, index) => (
                              <div
                                key={sourceTournament.id}
                                className="container-lg d-flex flex-column gap-3 border-b-2 p-2"
                              >
                                <h6>Source Tournament {index + 1}</h6>

                                <div className="row d-flex flex-col gap-3">
                                  <div
                                    className="col-12 col-md-12"
                                    style={{ height: "fit-content" }}
                                  >
                                    <Select
                                      defaultValue={
                                        multiTourFormData &&
                                        multiTourFormData.sourceTournaments[
                                          index
                                        ].region
                                      }
                                      onChange={async (e) => {
                                        await fetchSourceTours(e.label);
                                        await handleMultiTourFormData(
                                          "sourceTournaments",
                                          "region",
                                          e.label,
                                          index
                                        );
                                      }}
                                      options={regionOptions}
                                      placeholder="Select Region"
                                    />
                                    {errors &&
                                    errors[`source${index}region`] ? (
                                      <span className="text-danger">
                                        {errors[`source${index}region`]}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="col-12 col-md-12"
                                    style={{ height: "fit-content" }}
                                  >
                                    <Select
                                      defaultValue={tourna}
                                      onChange={async (e) => {
                                        fetchSourceTourRounds(
                                          multiTourFormData.sourceTournaments[
                                            index
                                          ].region,
                                          e.value
                                        );
                                        await Promise.all(
                                          handleMultiTourFormData(
                                            "sourceTournaments",
                                            "tour_name",
                                            e,
                                            index
                                          )
                                        );
                                      }}
                                      options={sourceTourOptions}
                                      placeholder="Select Tournament"
                                    />
                                    {errors &&
                                    errors[`source${index}tourName`] ? (
                                      <span className="text-danger">
                                        {errors[`source${index}tourName`]}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="col-12 col-md-12"
                                    style={{ height: "fit-content" }}
                                  >
                                    <Select
                                      defaultValue={
                                        multiTourFormData &&
                                        multiTourFormData.sourceTournaments[
                                          index
                                        ].round
                                      }
                                      onChange={(e) =>
                                        handleMultiTourFormData(
                                          "sourceTournaments",
                                          "round",
                                          e.value,
                                          index
                                        )
                                      }
                                      options={sourceTourRoundOptions}
                                      placeholder="Select Round 1"
                                    />
                                  </div>

                                  <div
                                    className="col-12 col-md-12"
                                    style={{ height: "fit-content" }}
                                  >
                                    <input
                                      type="number"
                                      id={`sourceTournament${index}NoOfPlayers`}
                                      className="form-control"
                                      value={
                                        sourceTournament.NumberOfPlayers === 0
                                          ? ""
                                          : sourceTournament.NumberOfPlayers
                                      }
                                      onChange={(e) =>
                                        handleMultiTourFormData(
                                          "sourceTournaments",
                                          "NumberOfPlayers",
                                          e.target.value,
                                          index
                                        )
                                      }
                                      placeholder="Number of players"
                                    />
                                    {errors &&
                                    errors[`source${index}NoPlayers`] ? (
                                      <span className="text-danger">
                                        {errors[`source${index}NoPlayers`]}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="col-12 col-md-2"
                                    style={{ height: "fit-content" }}
                                  >
                                    <Clear
                                      className={
                                        index === 0
                                          ? "d-none"
                                          : "cursor-pointer d-block"
                                      }
                                      onClick={() =>
                                        removeSourceTournament(index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        <div className="text-end">
                          <button
                            onClick={addSourceTournament}
                            className="sorce-tour-btn"
                          >
                            Add Tournament
                          </button>
                        </div>
                      </fieldset> */}

                      <div className="text-md-end text-center m-2">
                        <button
                          onClick={createMultiSourceTournament}
                          className="selecttourbutton"
                        >
                          Activate Tournament
                        </button>
                      </div>
                    </div>

                    <div className="border-t-2 p-4"></div>

                    {/* Tournament List Table */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateTournament;
