import { toast } from 'react-hot-toast';

const showToast = (message, code) => {
    const defaultOptions = {
        duration: 3000,
        position: 'top-center',
        style: {
            fontSize: '16px',
        },
    };

    if (code >= 400 && code <= 500) {
        return toast.error(message, defaultOptions);
    }

    return toast.success(message, defaultOptions);
};

export { showToast };
