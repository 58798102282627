import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    if (rest?.location?.pathname) {
        if(rest.location.pathname == '/player-selection' || rest.location.pathname == '/user-statistics') {
            localStorage.setItem('AdminPrevPath', '/tournament-list');
        }else{
            localStorage.setItem('AdminPrevPath', rest.location.pathname);
        }
    }
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;