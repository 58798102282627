import React from "react";

const Pagination = ({table}) => {
  return (
    <div className="d-flex flex-column flex-md-row gap-md-0 gap-2 w-100 p-2">
      <div className="d-flex flex-md-row flex-column w-100 justify-content-between align-items-center">
        <div className="d-flex flex-column flex-sm-row align-items-center gap-1">
          <h6>Rows per page:</h6>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <button
            type="button"
            style={{
              color: "#E5007D",
              border: "none",
            }}
            className="btn"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            style={{
              color: "#E5007D",
              border: "none",
            }}
            className="btn"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <div>
            Page{" "}
            {table.getPageCount()
              ? table.getState().pagination.pageIndex + 1
              : 0}{" "}
            of {table.getPageCount() ? table.getPageCount() : 0}
          </div>
          <button
            style={{
              color: "#E5007D",
              border: "none",
            }}
            className="btn"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            style={{
              color: "#E5007D",
              border: "none",
            }}
            className="btn"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
