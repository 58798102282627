import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
const authUrl = `${process.env.REACT_APP_APIURL}/auth`;

const initialState = {
    user: {},
    isAuthenticated: false,
}



const jsonconfig = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text/plain, */*",
    },
    withCredentials: true,
};

// Define the async thunk for fetching users
export const fetchUsers = createAsyncThunk(
    'users/fetchUsers', // Action type value
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${authUrl}/admin/load-user`, jsonconfig);
            if (!response.status === 200) {
                throw new Error('Network response was not ok');
            }
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(/* other middlewares if any */),
    reducers: {
        registerUser: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },

        loginUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true;
        },

        logoutUser: (state, action) => {
            localStorage.setItem('AdminPrevPath', '/login');
            state.user = {}
            state.isAuthenticated = false
        },


        loadUsers: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
        });
    }
})

export const { registerUser, loginUser, logoutUser, loadUsers } = userSlice.actions
export default userSlice.reducer;