import React from "react";
import "./components/Homepage.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Mainlogin from "./components/pages/Mainlogin.js";
// import adminpanel from "./components/pages/adminpanel.js";

import { useEffect } from "react";
import Allplayerslist from "./components/pages/Allplayerslist";

import CreateTournament from "./components/pages/CreateTournament.js";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "./app/features/userSlice.js";
import PrivateRoute from "./components/pages/PrivateRoute.js";
import { NotFound } from "./components/pages/NotFound.js";
import PlayerSelection from "./components/pages/PlayerSelection.js";
import TournamentList from "./components/pages/TourList.js";
import UserStats from "./components/pages/UserStats.jsx";
import specificStats from "./components/pages/specificStats.jsx";

export default function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(fetchUsers());
    }
  }, [isAuthenticated, user, dispatch]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <Router>
        <Switch>
          <Route exact strict path={"/"} component={Mainlogin} />
          <Route exact strict path={"/login"} component={Mainlogin} />
          <PrivateRoute
            exact
            strict
            path={"/players-list"}
            component={Allplayerslist}
          />
          <PrivateRoute
            exact
            strict
            path={"/create-tournament"}
            component={CreateTournament}
          />
          <PrivateRoute
            exact
            strict
            path={"/player-selection"}
            component={PlayerSelection}
          />
          <PrivateRoute
            exact
            strict
            path={"/tournament-list"}
            component={TournamentList}
          />
          <PrivateRoute
            exact
            strict
            path={"/user-stats"}
            component={UserStats}
          />
          <PrivateRoute
          exact 
          strict
          path={"/user-statistics"}
          component={specificStats}
          />
          <Route component={NotFound} />
          {/* <PrivateRoute exact strict path={'/adminpanel/'} component={adminpanel} /> */}
        </Switch>
      </Router>
    </>
  );
}
