import { configureStore } from "@reduxjs/toolkit";
import teamReducer from '../app/features/teamSlice'
import userReducer from '../app/features/userSlice'

export const store = configureStore({
    reducer: {
        team: teamReducer,
        user: userReducer
    }
});


