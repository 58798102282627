/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import MaterialTable from "material-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { supabase } from "../../supabaseClient";
import { forwardRef } from "react";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  Search,
  ViewColumn,
  Refresh,
  UnfoldMore,
  ExpandLess,
  ArrowDownward,
  ExpandMore,
  ArrowDropUp,
  ArrowDropDown,
} from "@material-ui/icons";
import logoimages from "../../images/logo_1.png";
import Logo1 from "../../images/Screenshot_1.png";
import { useHistory, Link } from "react-router-dom";
import "../../components/Homepage.css";
import * as _ from "lodash";

import {
  fetchLatestTournament,
  fetchLeagueCategories,
  fetchLeagues,
  fetchTournament,
  fetchTournamentList,
  fetchTournamentPlayers,
  fetchTournaments,
  playerUpdateAutomation,
  updatePlayer,
  updatePlayers,
  userLogout,
} from "../../api";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setTeam } from "../../app/features/teamSlice";
import { showToast } from "../toast";
import { logoutUser } from "../../app/features/userSlice";
import { TextField } from "@material-ui/core";
import moment from "moment";
import MultiRangeSlider from "multi-range-slider-react";

const Playerslist = () => {
  const dispatch = useDispatch();
  const teamData = useSelector((state) => state.team.team);

  const [playerslistData, setPlayerslistdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkEvent, setCheckEvent] = useState([]);
  const [labels, setLabels] = useState([]);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(10);
  const [minPlayerPrice, setMinPlayerPrice] = useState(minPrice);
  const [maxPlayerPrice, setMaxPlayerPrice] = useState(maxPrice);
  const history = useHistory();

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDropDown {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const columns = [
    { title: "Name", field: "playerName", editable: "never" },
    // {title: "Round", field: "round", editable: 'never', render: rowData => rowData.round.split("_").join(" ")},
    { title: "Price", field: "price" },
    { title: "Points Scored", field: "points", editable: "never" },
  ];

  const handleLogout = async () => {
    const response = await userLogout();
    if (response.status === 200) {
      // TODO: Remove user from redux store

      dispatch(logoutUser());
      showToast("Successfully logged out", 200);
      history.push({
        pathname: "/login",
      });
    }
  };

  const handlePriceUpdate = async (playersToUpdate, formData) => {
    const response = await updatePlayer({ playersToUpdate, formData });

    if (response.status === 200) {
      refreshPlayers();
    }
  };

  // <!-- ===== New Logic ===== -->

  const [playerList, setPlayerList] = useState([]);
  const [leagueCatOptions, setLeagueCatOptions] = useState([]);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [tourOptions, setTourOptions] = useState([]);

  const [formData, setFormData] = useState({
    league: "Global League",
    league_cat: "",
    league_type: 1,
    tournament: "",
  });

  const handleFormChange = (key, val) => {
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const [errors, setErrors] = useState(null);

  const handleDataFetch = async () => {
    let tempErrors = {};

    if (formData.league_cat === 0 || formData.league_cat === "") {
      tempErrors["league_cat"] = "Please Select League Category";
    }

    if (formData.league.trim().length === 0) {
      tempErrors["league"] = "Please Select League";
    }

    if (formData.tournament === 0 || formData.tournament === "") {
      tempErrors["tournament"] = "Please Select Tournament";
    }

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) {
      dispatch(setTeam(formData));
      fetchPlayers();
    }
  };

  // fetch league categories
  useEffect(() => {
    async function getleagueCats() {
      const response = await fetchLeagueCategories();
      setLeagueCatOptions([]);
      setTourOptions([]);
      setFormData((prevData) => ({
        ...prevData,
        tournament: "",
      }));
      if (response.status === 200) {
        setLeagueCatOptions(response?.data?.data);
        setErrors((prev) => ({ ...prev, tournament: "" }));
      }
    }

    getleagueCats();
  }, []);

  useEffect(() => {
    async function fetchAndSetLatestTournament() {
      setLoading(true);

      try {
        const response = await fetchLatestTournament();

        if (response.status === 200) {
          const result = await fetchTournament({
            league: "Global League",
            league_cat: response.data.data.leaguecategory,
            league_type: 1,
          });

          if (result?.data?.data?.length === 0) {
            console.log("No latest tournament found");
          } else {
            const currentTimeInETC = moment().tz("America/New_York");

            for (const tour of result.data.data) {
              let roundEndDate = tour.isCumulative
                ? moment(tour.round1_end_date).tz("America/New_York")
                : moment(tour.round2_end_date).tz("America/New_York");

              if (currentTimeInETC.isBefore(roundEndDate)) {
                setFormData((prev) => ({
                  ...prev,
                  league_cat: response.data.data.leaguecategory,
                  tournament: tour.value,
                }));

                const res = await fetchTournamentPlayers({
                  tournament: tour.value,
                  minPrice: minPlayerPrice || 1,
                  maxPrice: maxPlayerPrice || 10,
                });

                if (res?.status === 200) {
                  setMinPrice(res.data.min || 0);
                  setMaxPrice(res.data.max || 0);
                  setPlayerList(res?.data?.data);
                }

                break;
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching latest tournament:", error);
      }

      setLoading(false);
    }

    fetchAndSetLatestTournament();
  }, []);

  //fetch tournaments
  useEffect(() => {
    async function getTournaments() {
      setTourOptions([]);

      setErrors((prev) => ({ ...prev, tournament: "" }));
      const response = await fetchTournaments(formData);

      if (response.status === 200) {
        let currentTimeInETC = moment(new Date(Date.now()))
          .tz("America/New_York")
          .format("YYYY-MM-DD HH:mm:ss");

        let tourOpts = response.data.data;
        let filteredTours = tourOpts.filter((tour) => {
          let isCumulative = tour.isCumulative;
          let roundEndDate = isCumulative
            ? tour.round1_end_date
            : tour.round2_end_date;

          if (roundEndDate) {
            let roundEndMoment = moment(roundEndDate);
            if (moment(currentTimeInETC).isAfter(roundEndMoment)) {
              return false;
            }
          }

          return true;
        });

        if (filteredTours.length === 0) {
          setErrors((prev) => ({
            ...prev,
            tournament: "No tournament exists in given league",
          }));
        }
        setTourOptions(filteredTours);
      }

      if (response.status === 400 && response.data.additionalData.noTourFound) {
        setTourOptions([]);
        setFormData((prev) => ({ ...prev, tournament: "" }));
        return setErrors((prev) => ({
          ...prev,
          tournament: "No tournament exists in given league",
        }));
      }
    }
    getTournaments();
  }, [formData.league_cat]);

  // fetch players
  async function fetchPlayers() {
    console.log("Called");
    setLoading(true);
    const response = await fetchTournamentPlayers({
      ...formData,
      minPrice: minPlayerPrice || minPrice,
      maxPrice: maxPlayerPrice || maxPrice,
    });

    if (response?.status === 200) {
      setMinPrice(response?.data?.min);
      setMaxPrice(response?.data?.max);
      setPlayerList(response?.data?.data);
    }

    if (response.status === 400) {
      if (response.data.additionalData.noPlayersFound) {
        showToast("No players found in selected tournament", 400);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const debounceFilteredPlayers = _.debounce(async function () {
      try {
        setLoading(true);

        const response = await fetchTournamentPlayers({
          ...formData,
          minPrice: minPlayerPrice || minPrice,
          maxPrice: maxPlayerPrice || maxPrice,
        });

        if (response?.status === 200) {
          setMinPrice(response?.data?.min || minPrice);
          setMaxPrice(response?.data?.max || maxPrice);
          setPlayerList(response?.data?.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }, 1000);

    debounceFilteredPlayers();

    return () => {
      debounceFilteredPlayers.cancel();
    };
  }, [minPlayerPrice, maxPlayerPrice]);

  // refresh players
  const refreshPlayers = _.debounce(async function () {
    setLoading(true);
    const refresh = await fetchTournamentPlayers(formData);
    if (refresh.status === 200 && refresh.data.data.length !== 0) {
      setPlayerList(refresh.data.data);
    } else {
      showToast("Failed to fetch players", 400);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, 500);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  useEffect(() => {
    let labels = [];
    for (let i = minPrice; i <= maxPrice; i++) {
      labels = [...labels, i];
    }
    setLabels(labels);
  }, [minPrice, maxPrice]);

  // <!-- ===== New Logic ===== -->

  const [isAutomations, setIsAutomation] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [automatePlayers, setAutomatePlayers] = useState([]);
  const [oldPlayers, setOldPlayers] = useState([]);
  const [apiPlayers, setApiPlayers] = useState([]);
  const [automateFormData, setAutomateFormData] = useState();
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const handleClick = async () => {
    const finalData = {
      oldPlayers,
      apiPlayers,
    };
    const response = await playerUpdateAutomation(finalData);

    if (response.data.success) {
      setAutomatePlayers(response.data.matchedPlayers);
    }
  };

  const handleSelectPlayer = (player) => {
    const isSelected = selectedPlayers.find(
      (selectedPlayer) => selectedPlayer.player_id === player.player_id
    );

    if (!isSelected) {
      // Add the player to the selected players list
      setSelectedPlayers([...selectedPlayers, player]);
    } else {
      // Remove the player from the selected players list
      const newSelectedPlayers = selectedPlayers.filter(
        (selectedPlayer) => selectedPlayer.player_id !== player.player_id
      );
      setSelectedPlayers(newSelectedPlayers);
    }
  };

  const handlePlayerChange = (index, key, val) => {
    const updatedPlayers = [...automatePlayers];

    updatedPlayers[index][key] = val;

    setAutomatePlayers(updatedPlayers);
  };

  const updateSelectedPlayers = async () => {
    const response = await updatePlayers({ allPlayers: selectedPlayers });
  };

  const updateAllPlayers = async () => {
    const response = await updatePlayers({ allPlayers: automatePlayers });
  };

  // Ensure minPlayerPrice cannot exceed maxPlayerPrice, and vice versa
  const handleMinPriceChange = (value) => {
    const newValue = Math.min(value, maxPlayerPrice); // Prevent going above max
    setMinPlayerPrice(newValue);
  };

  const handleMaxPriceChange = (value) => {
    const newValue = Math.max(value, minPlayerPrice); // Prevent going below min
    setMaxPlayerPrice(newValue);
  };

  let checkingStatus = checkEvent && checkEvent[0];
  let checkEventStatus = checkingStatus && checkingStatus.status;
  let checkEventEventname = checkingStatus && checkingStatus.tournamentname;
  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <>
            <div className="contenorlanding">
              <div className="contentrow col-12">
                <div className="nevcolumn col-2">
                  <div className="nevlogo">
                    <img className="photoimagelanding" src={Logo1} alt="logo" />
                  </div>
                  <div className="nevlistst">
                    <li className="listnevbars">
                      <Link
                        to="/players-list"
                        className="text-decoration-none text-black"
                      >
                        Player List
                      </Link>
                    </li>
                    {/* <li className="listnevbars">
                      <Link
                        to="/tournament-list"
                        className="text-decoration-none text-black"
                      >
                        Tournament List
                      </Link>
                    </li> */}

                    <div
                      className="accordion accordion-flush"
                      id="accordionExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            style={{ fontSize: "16px", pointerEvents: "none" }}
                            className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Tournament
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion show collapse px-2"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                            <Link
                              to="/tournament-list"
                              className="text-decoration-none text-black px-2"
                            >
                              <span style={{ fontSize: "14px" }}>List</span>
                            </Link>
                          </div>
                          <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                            <Link
                              to="/create-tournament"
                              className="text-decoration-none text-black text-sm px-2"
                            >
                              <span style={{ fontSize: "14px" }}>Create</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <li className="listnevbars">
                      <Link
                        to="/user-stats"
                        className="text-decoration-none text-black"
                      >
                        Users
                      </Link>
                    </li>
                  </div>
                </div>
                <div
                  className="col-10 screensection"
                  style={{ height: "auto", background: "#F8F8F8" }}
                >
                  <div className="landingRow col-12 mb-4">
                    <div className="nevbarbutton p-0">
                      <button
                        className="nevbarlogbutton m-0"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                  <div className="landingsscreens">
                    <div className="landingscreen px-4">
                      {/* Form */}
                      <div className="form">
                        <form
                          action="POST"
                          className="d-flex flex-column gap-3"
                        >
                          <div>
                            <Select
                              value={
                                leagueCatOptions.find(
                                  (o) => o.value === formData.league_cat
                                ) || null
                              }
                              options={leagueCatOptions}
                              placeholder="Select League Category"
                              onChange={(e) =>
                                handleFormChange("league_cat", e.value)
                              }
                            />
                            {errors && errors.league_cat ? (
                              <span className="text-danger">
                                {errors.league_cat}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <div>
                          <Select
                            options={leagueOptions}
                            placeholder="Select League"
                            onChange={(e) => handleFormChange('league', e.value)}
                          />
                          {
                            errors && errors.league ? (
                              <span className="text-danger">{errors.league}</span>
                            ) : ("")
                          }
                        </div> */}

                          <div>
                            <Select
                              value={
                                tourOptions.find(
                                  (o) => o.value === formData.tournament
                                ) || null
                              }
                              options={tourOptions}
                              placeholder="Select Tournament"
                              onChange={(e) =>
                                handleFormChange("tournament", e.value)
                              }
                            />
                            {errors && errors.tournament ? (
                              <span className="text-danger">
                                {errors.tournament}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <button
                            className="selecttourbutton"
                            type="button"
                            onClick={handleDataFetch}
                          >
                            Fetch Players
                          </button>
                        </form>
                      </div>

                      {/* {minPrice !== maxPrice && (
                        <div className="my-3">
                          <div className="px-1">
                            <span>Price Filter</span>
                          </div>
                          <MultiRangeSlider
                            min={minPrice || 1}
                            max={maxPrice || 10}
                            minValue={minPlayerPrice || 1}
                            maxValue={maxPlayerPrice || 10}
                            ruler={true}
                            label={true}
                            labels={labels}
                            step={1}
                            onChange={(e) => {
                              setMinPlayerPrice(e.minValue);
                              setMaxPlayerPrice(e.maxValue);
                            }}
                            barInnerColor="#e5007d"
                          />
                        </div>
                      )} */}

                      <div className="my-3">
                        {playerList &&
                          playerList.length > 0 &&
                          minPrice !== maxPrice && (
                            <div>
                              <div className="m-2 text-center">
                                <span>Price Filter</span>
                              </div>
                              <div className="d-flex flex-column align-items-center gap-1">
                                <div className="d-flex align-items-center gap-3">
                                  {/* Minimum Price Input */}
                                  <div className="d-flex  flex-column align-items-center gap-1 border w-fit p-2 rounded-md">
                                    <label>Min</label>
                                    <input
                                      type="number"
                                      className="border-none form-control "
                                      min={minPrice}
                                      max={maxPrice}
                                      value={minPlayerPrice || ""}
                                      onChange={(e) =>
                                        handleMinPriceChange(
                                          Number(e.target.value)
                                        )
                                      }
                                      placeholder="Minimum"
                                    />
                                  </div>

                                  <div>
                                    <Remove />
                                  </div>

                                  {/* Maximum Price Input */}
                                  <div className="d-flex  flex-column align-items-center gap-1 border w-fit p-2 rounded-md">
                                    <label>Max</label>

                                    <input
                                      type="number"
                                      className="border-none form-control "
                                      min={minPlayerPrice}
                                      max={maxPrice}
                                      value={maxPlayerPrice || maxPrice}
                                      onChange={(e) =>
                                        handleMaxPriceChange(
                                          Number(e.target.value)
                                        )
                                      }
                                      placeholder="Maximum"
                                    />
                                  </div>
                                </div>

                                {/* MultiRangeSlider */}
                                {/* <MultiRangeSlider
                                min={minPrice}
                                max={maxPrice}
                                minValue={minPlayerPrice}
                                maxValue={maxPlayerPrice}
                                ruler={false}
                                onChange={(e) => {
                                  setMinPlayerPrice(e.minValue);
                                  setMaxPlayerPrice(e.maxValue);
                                }}
                                step={1}
                                barInnerColor="#e6007e"
                                className="border-none"
                                preventWheel={true}
                              /> */}
                              </div>
                            </div>
                          )}
                      </div>

                      {/* Table */}
                      <div className="mt-3">
                        <div
                          className={`w-full text-end my-2 ${
                            playerList.length === 0 ? "d-block" : "d-hidden"
                          }`}
                        >
                          <button
                            onClick={refreshPlayers}
                            className={`${
                              playerList.length === 0
                                ? "refresh-btn-disabled "
                                : "refresh-btn block"
                            }`}
                            disabled={playerList.length === 0}
                          >
                            <Refresh className={`fs-2  px-1 py-1`} />
                          </button>
                        </div>

                        {playerList && playerList.length !== 0 && (
                          <MaterialTable
                            data={playerList}
                            title="Player List"
                            icons={tableIcons}
                            columns={columns}
                            isLoading={loading}
                            options={{
                              actionsColumnIndex: -2,
                              sorting: true,
                              search: true,
                              pageSize: 50,
                              emptyRowsWhenPaging: false,
                              pageSizeOptions: [5, 10, 20, 50],
                            }}
                            editable={{
                              onBulkUpdate: (selectedRows) =>
                                new Promise((resolve, reject) => {
                                  let payload = Object.values(selectedRows);
                                  setTimeout(() => {
                                    resolve();
                                    setPlayerList((prevList) =>
                                      [...prevList].sort((a, b) => a.id - b.id)
                                    );
                                    handlePriceUpdate(payload, formData);
                                  }, 500);
                                }),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Add className = displayNone to this internal tool */}
                  <div className="p-3 displayNone">
                    <div className="w-100">
                      <TextField
                        className="w-100"
                        onChange={(e) =>
                          setOldPlayers(JSON.parse(e.target.value))
                        }
                        placeholder="old players"
                      />
                    </div>
                    <div className="w-100">
                      <TextField
                        onChange={(e) =>
                          setApiPlayers(JSON.parse(e.target.value))
                        }
                        className="w-100"
                        placeholder="new players"
                      />
                    </div>
                    <div className="p-2 mt-3">
                      <buttonl
                        className="selecttourbutton"
                        onClick={handleClick}
                      >
                        Fetch Matching Players
                      </buttonl>
                    </div>

                    {automatePlayers && automatePlayers.length > 0 && (
                      <>
                        <h3>Matched Players</h3>
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  style={{ display: "block" }}
                                  onChange={() =>
                                    setSelectedPlayers(automatePlayers)
                                  }
                                />
                              </th>
                              <th>Sr No.</th>
                              <th>DB Player Name</th>
                              <th>API Player Name</th>
                              <th>Accuracy</th>
                              <th>Old Player Id</th>
                              <th>New Player Id</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {automatePlayers &&
                              automatePlayers.length > 0 &&
                              automatePlayers.map((val, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    border:
                                      val.similarityScore <= 0.33
                                        ? "3px solid red"
                                        : "",
                                  }}
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="d-block"
                                      checked={selectedPlayers.some(
                                        (player) =>
                                          player.player_id === val.player_id
                                      )}
                                      onChange={() => handleSelectPlayer(val)}
                                    />
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input value={val.name} />
                                  </td>
                                  <td>
                                    <input value={val.apiName} />
                                  </td>
                                  <td>
                                    <input value={val.similarityScore} />
                                  </td>
                                  <td>
                                    <input
                                      value={val.player_id}
                                      onChange={(e) =>
                                        handlePlayerChange(
                                          index,
                                          "player_id",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      value={val.newId}
                                      onChange={(e) =>
                                        handlePlayerChange(
                                          index,
                                          "newId",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  {/* <td>
                                <button>Update</button>
                              </td> */}
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        <div className="p-2">
                          <button
                            className="selecttourbutton"
                            onClick={updateSelectedPlayers}
                          >
                            Update Selected Players
                          </button>
                        </div>

                        <div className="p-2">
                          <button
                            className="selecttourbutton"
                            onClick={updateAllPlayers}
                          >
                            Update All Players
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Internal Tool to update players */}
          </>
        )}
      </div>
    </>
  );
};
export default Playerslist;
