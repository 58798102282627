import { VerticalSplit } from "@material-ui/icons";
import React from "react";
export const NotFound = () => {
  return (
    <>
      <div style={{height: '100vh'}} className="p-5 d-flex h-full w-full justify-content-center gap-3 align-items-center" id="main">
        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">
          404
        </h1>
        <div className="border border-secondary" style={{height: '30px'}}></div>
        <div className="inline-block align-middle">
          <h2 className="font-weight-normal lead" id="desc">
            The page you requested was not found.
          </h2>
        </div>
      </div>
    </>
  );
};
