import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Logo1 from "../../images/Screenshot_1.png";
import {
  createMultiSourceTour,
  createTournament,
  externalCall,
  fetchSourceTourPlayers,
  userLogout,
} from "../../api";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/features/userSlice";
import { showToast } from "../toast";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import logoimages from "../../images/logo_1.png";

function PlayerSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState();
  const [sourceTournaments, setSourceTournaments] = useState();
  const [editingPlayerId, setEditingPlayerId] = useState(null);

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    field: "roundscore",
    order: "desc",
  });

  const sortPlayers = (players) => {
    const { field, order } = sortOrder;

    // If no field is selected for sorting, return players as is
    if (!field) return players;

    return players.sort((a, b) => {
      if (a[field] > b[field]) {
        return order === "asc" ? 1 : -1;
      } else if (a[field] < b[field]) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    });
  };

  const handleSort = (tournament, teams, field) => {
    const isSameField = field === sortOrder.field;
    const newOrder = isSameField && sortOrder.order === "asc" ? "desc" : "asc";

    setSortOrder({
      field,
      order: newOrder,
    });

    // Sorting happens automatically when you call the rendering function, no need to directly modify teams here
  };

  const isCumulative = location.state && location.state.isCumulative; // true if tournament is cumulative, else false
  const multiSourceFormData = location.state && location.state.multiSourceData;
  const formData = location.state && location.state.formData;

  useEffect(() => {
    if (isCumulative) {
      console.log("cumulative");
      setSourceTournaments(multiSourceFormData.sourceTournaments);
    } else {
      console.log("normal");
      setSourceTournaments(formData.sourceTournaments);
    }
  }, [isCumulative]);

  useEffect(() => {
    async function fetchSourcePlayers() {
      setLoading(true);
      if (sourceTournaments && sourceTournaments.length > 0) {
        const response = await fetchSourceTourPlayers(sourceTournaments);
        if (response.status === 200) {
          setData(response.data);
        }
        setLoading(false);
      }
    }
    fetchSourcePlayers();
  }, [sourceTournaments, isCumulative]);

  const handleSelectAll = (players) => {
    const areAllSelected = players.every((player) =>
      selectedPlayers.some(
        (selected) => selected.player_id === player.player_id
      )
    );

    if (areAllSelected) {
      // Deselect all players for this tournament
      setSelectedPlayers((prev) =>
        prev.filter(
          (pl) => !players.some((player) => player.player_id === pl.player_id)
        )
      );
    } else {
      // Select all players for this tournament
      const newSelected = players.filter(
        (player) =>
          !selectedPlayers.some((pl) => pl.player_id === player.player_id)
      );
      setSelectedPlayers((prev) => [...prev, ...newSelected]);
    }
  };

  const isPlayerSelected = (player_id) => {
    return selectedPlayers.some((player) => player.player_id === player_id);
  };

  const handlePlayerSelect = (player) => {
    const isSelected = isPlayerSelected(player.player_id);

    if (isSelected) {
      // Deselect player
      setSelectedPlayers((prev) =>
        prev.filter((pl) => pl.player_id !== player.player_id)
      );
    } else {
      // Select player
      setSelectedPlayers((prev) => [...prev, player]);
    }
  };

  const isSelectedAll = (players) => {
    return players.every((player) =>
      selectedPlayers.some(
        (selected) => selected.player_id === player.player_id
      )
    );
  };

  const updatePlayerPrice = (player_id, newPrice) => {
    setSelectedPlayers((prev) =>
      prev.map((player) =>
        player.player_id === player_id ? { ...player, price: newPrice } : player
      )
    );
  };

  const handlePriceChange = (player_id, newPrice, tournament, team) => {
    // console.log(newPrice)
    // console.log(selectedPlayers)

    updatePlayerPrice(player_id, newPrice); // Assuming this updates the backend

    // Create a deep copy of the data object to avoid mutating the state directly
    const updatedData = { ...data };

    // Traverse to the specific player and update their price
    updatedData[tournament][team] = updatedData[tournament][team].map(
      (player) => {
        if (player.player_id === player_id) {
          return { ...player, price: newPrice }; // Update the price immutably
        }
        return player;
      }
    );

    // Set the new data object with updated price
    setData(updatedData); // Assuming `setData` is the state setter for `data`

    setEditingPlayerId(player_id); // Set the ID of the player being edited
  };
  const handleButtonClick = () => {
    if (isCumulative) {
      createMultiSourceTournament();
    } else {
      createNormalTournament();
    }
  };

  //   Create multi source tournament entry in DB and Insert players
  const createMultiSourceTournament = async () => {
    if (selectedPlayers.length === 0) {
      showToast("Please select players", 400);
      return;
    }

    let finalData = {
      ...multiSourceFormData,
      selectedPlayers: selectedPlayers,
    };

    const response = await createMultiSourceTour(finalData);
    setLoading(true);

    if (response.status === 200) {
      showToast(response.data.message, 200);

      setTimeout(async () => {
        await externalCall();
      }, 500);
    }
    if (response.status >= 400 && response.status <= 500) {
      showToast("Something went wrong", response.status);
    }
    setLoading(false);

    setTimeout(() => {
      history.push({
        pathname: "/tournament-list",
      });
    }, 1000);
  };

  const createNormalTournament = async () => {
    if (selectedPlayers.length === 0) {
      showToast("Please select players", 400);
      return;
    }

    let finalData = {
      formData: formData.formData,
      selectedPlayers,
    };

    setLoading(true);

    // create tournament
    const response = await createTournament(finalData);

    if (response.status === 200) {
      showToast(response.data.message, response.status);

      setTimeout(async () => {
        await externalCall();
      }, 500);
    }
    if (response.status >= 400 && response.status <= 500) {
      if (
        response.status === 400 &&
        response?.data?.additionalData?.alreadyExists
      ) {
        setLoading(false);
        return showToast(response.data.message, response.status);
      } else {
        setLoading(false);
        return showToast(500, "Something went wrong");
      }
    }

    setTimeout(() => {
      history.push({
        pathname: "/tournament-list",
      });
    }, 1000);
  };

  const handleLogout = async () => {
    const response = await userLogout();
    if (response.status === 200) {
      dispatch(logoutUser());
      showToast("Successfully logged out", 200);
      history.push({
        pathname: "/login",
      });
    }
  };
  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <div className="contenorlanding">
            <div className="contentrow col-12">
              <div className="nevcolumn col-2">
                <div className="nevlogo">
                  <img className="photoimagelanding" src={Logo1} alt="logo" />
                </div>
                <div className="nevlistst">
                  <li className="listnevbars">
                    <Link
                      to="/players-list"
                      className="text-decoration-none text-black"
                    >
                      Player List
                    </Link>
                  </li>
                  {/* <li className="listnevbars">
                    <Link
                      to="/tournament-list"
                      className="text-decoration-none text-black"
                    >
                      Tournament List
                    </Link>
                  </li> */}

                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "16px", pointerEvents: "none" }}
                          className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Tournament
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion show collapse px-2"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/tournament-list"
                            className="text-decoration-none text-black px-2"
                          >
                            <span style={{ fontSize: "14px" }}>List</span>
                          </Link>
                        </div>
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/create-tournament"
                            className="text-decoration-none text-black text-sm px-2"
                          >
                            <span style={{ fontSize: "14px" }}>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>
              <div
                className="col-10 screensection"
                style={{ height: "auto", background: "#F8F8F8" }}
              >
                <div className="landingRow col-12 mb-4">
                  <div className="nevbarbutton p-0">
                    <button
                      className="nevbarlogbutton m-0"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className="landingscreen container-fluid p-4">
                  <div
                    className="sticky-top shadow-sm"
                    style={{
                      background: "#f8f8f8",
                      textAlign: "end",
                      padding: "3px",
                    }}
                  >
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="text-start">
                        <div>
                          <span style={{ color: "#E5007D" }}>
                            Please select the player to update the price
                          </span>
                        </div>
                        <div className="fw-bold">
                          Selected Players:
                          {(selectedPlayers && selectedPlayers.length) || 0}
                        </div>
                      </div>
                      <div>
                        <div className="text-end px-4 py-1">
                          <button
                            className="selecttourbutton"
                            onClick={handleButtonClick}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data &&
                    data.regions.map((region) => (
                      <div className="card mb-4 p-3" key={region}>
                        <h2>{region}</h2>
                        {Object.entries(data.data[region]).map(
                          ([tournament, teams]) => (
                            <div className="card-body" key={tournament}>
                              <h3>{tournament.split("_").join(" ")}</h3>
                              <table
                                className="table table-light table-striped-columns"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        style={{ display: "block" }}
                                        type="checkbox"
                                        onChange={() => handleSelectAll(teams)}
                                      />
                                    </th>
                                    <th>Team Name</th>
                                    <th style={{ cursor: "pointer" }}>Price</th>
                                    <th
                                      onClick={() =>
                                        handleSort(
                                          tournament,
                                          teams,
                                          "roundscore"
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Score{" "}
                                      {sortOrder.field === "roundscore" &&
                                        (sortOrder.order === "asc" ? (
                                          <span>&uarr;</span> // Up arrow for ascending
                                        ) : (
                                          <span>&darr;</span> // Down arrow for descending
                                        ))}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {teams.map((team) => {
                                    const selectedPlayer = selectedPlayers.find(
                                      (player) =>
                                        player.player_id === team.player_id
                                    );
                                    const displayPrice = selectedPlayer
                                      ? selectedPlayer.price
                                      : team.price;

                                    return (
                                      <tr key={team.player_id}>
                                        <td>
                                          <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            onChange={() =>
                                              handlePlayerSelect(team)
                                            }
                                            checked={isPlayerSelected(
                                              team.player_id
                                            )}
                                          />
                                        </td>
                                        <td>{team.name}</td>
                                        <td>
                                          <input
                                            type="number"
                                            disabled={
                                              isPlayerSelected(team.player_id)
                                                ? false
                                                : true
                                            }
                                            className="form-control"
                                            value={displayPrice}
                                            onChange={(e) =>
                                              handlePriceChange(
                                                team.player_id,
                                                Number(e.target.value)
                                              )
                                            }
                                          />
                                        </td>
                                        <td>{team.roundscore}</td>
                                      </tr>
                                    );
                                  })} */}

                                  {sortPlayers(teams).map((team) => {
                                    const selectedPlayer = selectedPlayers.find(
                                      (player) =>
                                        player.player_id === team.player_id
                                    );
                                    const displayPrice = selectedPlayer
                                      ? selectedPlayer.price
                                      : team.price;

                                    return (
                                      <tr key={team.player_id}>
                                        <td>
                                          <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            onChange={() =>
                                              handlePlayerSelect(team)
                                            }
                                            checked={isPlayerSelected(
                                              team.player_id
                                            )}
                                          />
                                        </td>
                                        <td>{team.name}</td>
                                        <td>
                                          <input
                                            type="number"
                                            disabled={
                                              !isPlayerSelected(team.player_id)
                                            }
                                            className="form-control"
                                            value={displayPrice || ""}
                                            min={1}
                                            onChange={(e) =>
                                              handlePriceChange(
                                                team.player_id,
                                                Number(e.target.value),
                                                tournament,
                                                team
                                              )
                                            }
                                          />
                                        </td>
                                        <td>{team.roundscore}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="text-end px-4 py-1">
                  <button
                    className="selecttourbutton"
                    onClick={handleButtonClick}
                  >
                    Proceed
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PlayerSelection;
