/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  fetchTeamPlayersOfUser,
  fetchTournamentLeaderboards,
  getSpecificUserStats,
  getUserSpecificLeaderboards,
  userLogout,
} from "../../api";
import logoimages from "../../images/logo_1.png";
import Logo1 from "../../images/Screenshot_1.png";
import Select from "react-select";
import lodash from "lodash";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/features/userSlice";
import { showToast } from "../toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TrophyIcon from "../../assets/trophy.png";
import JoinLeagueIcon from "../../assets/joinleague.png";
import BaseTable from "./data-table/BaseTable";
import { Clear, Visibility } from "@material-ui/icons";

const specificStats = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.state && location.state.id;
  const [loading, setLoading] = useState(false);

  const [globalData, setGlobalData] = useState([]);
  const [privateLeaguesOfUser, setPrivateLeaguesOfUser] = useState([]);
  const [privateLeaguesJoinedByUser, setPrivateLeaguesJoinedByUser] = useState(
    []
  );
  const [categoryOptions, setCategoryOptions] = useState([
    {
      value: 0,
      label: "All Regions",
    },
  ]);
  const [leagueCategory, setLeagueCategory] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [leaderboards, setLeaderboards] = useState();
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    async function getUserStats() {
      try {
        setLoading(true);
        const response = await getSpecificUserStats({
          id: id,
          leagueCategory: leagueCategory,
        });

        if (response.status === 200) {
          if (response.data && response.data.data.globalData.length)
            setGlobalData(response.data.data.globalData);

          if (
            response.data &&
            response.data.data.privateLeaguesCreatedByUser.length
          )
            setPrivateLeaguesOfUser(
              response.data.data.privateLeaguesCreatedByUser
            );

          if (response.data && response.data.data.privateLeaguesJoinedByUser)
            setPrivateLeaguesJoinedByUser(
              response.data.data.privateLeaguesJoinedByUser
            );

          if (response.data && response.data.data.regions.length) {
            const uniqueOptions =
              response.data &&
              response.data.data.regions.length &&
              lodash.uniqBy(
                [...categoryOptions, ...response.data.data.regions],
                "value"
              );
            setCategoryOptions(uniqueOptions);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    getUserStats();
  }, [id, leagueCategory]);

  useEffect(() => {
    async function getLeaderboards() {
      try {
        setLoading(true);
        const response = await getUserSpecificLeaderboards({});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    getLeaderboards();
  }, []);

  async function fetchTourLeaderboards(data) {
    try {
      setLoading(false);
      setIsDialogOpen(true);

      const response = await fetchTournamentLeaderboards({
        tournament: data.tour_id,
        league_id: data.league_id,
        leagueType: data.league_type_id,
        league_cat: data.league_category_id,
      });

      if (response.status === 200) {
        setLeaderboards(response.data.data);

        // fetch team players of user team
        const result = await fetchTeamPlayersOfUser({
          team_id: data.team_id,
          tour_id: data.tour_id,
        });

        if (result.status === 200) {
          setPlayers(result.data.data);
        }
      } else {
        showToast("Something went wrong", 400);
        setIsDialogOpen(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleLogout = async () => {
    const response = await userLogout();

    if (response?.status === 200) {
      dispatch(logoutUser());
      showToast(response?.data?.message, response?.status);
      history.push({
        pathname: "/login",
      });
    }
  };

  /** @type import('@tanstack/react-table').ColumnDef<any>[] */
  const globalDataColumns = [
    {
      accessorKey: "category_name",
      header: "Region",
    },
    {
      accessorKey: "beutifiedname",
      header: "Tournament",
      cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {!data.iscumulative
              ? data.beutifiedname.split("-")[0] +
                " - " +
                data.beutifiedname.split("-")[
                  data.beutifiedname.split("-").length - 1
                ]
              : data.beutifiedname.split("-")[0]}
          </div>
        );
      },
    },
    {
      accessorKey: "teamname",
      header: "Team",
    },
    {
      accessorKey: "total_points",
      header: "Total Points",
    },
    {
      id: "action",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <Visibility
            onClick={() => fetchTourLeaderboards(data)}
            style={{
              color: "#E5007D",
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  /** @type import('@tanstack/react-table').ColumnDef<any>[] */
  const privateLeagueOfUserColumns = [
    {
      accessorKey: "category_name",
      header: "Region",
    },
    {
      accessorKey: "tour_name",
      header: "Tournament",
      cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {!data.iscumulative
              ? data.beutifiedname.split("-")[0] +
                " - " +
                data.beutifiedname.split("-")[
                  data.beutifiedname.split("-").length - 1
                ]
              : data.beutifiedname.split("-")[0]}
          </div>
        );
      },
    },
    {
      accessorKey: "league_name",
      header: "League",
    },
    {
      accessorKey: "league_code",
      header: "League Code",
    },
    {
      id: "action",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <Visibility
            onClick={() => fetchTourLeaderboards(data)}
            style={{
              color: "#E5007D",
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  /** @type import('@tanstack/react-table').ColumnDef<any>[] */
  const privateLeaguesJoinedColumns = [
    {
      accessorKey: "category_name",
      header: "Region",
    },
    {
      accessorKey: "tour_name",
      header: "Tournament",
      cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {!data.iscumulative
              ? data.beutifiedname.split("-")[0] +
                " - " +
                data.beutifiedname.split("-")[
                  data.beutifiedname.split("-").length - 1
                ]
              : data.beutifiedname.split("-")[0]}
          </div>
        );
      },
    },
    {
      accessorKey: "league_name",
      header: "League",
    },
    {
      accessorKey: "league_code",
      header: "League Code",
    },
    {
      id: "action",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <Visibility
            onClick={() => fetchTourLeaderboards(data)}
            style={{
              color: "#E5007D",
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  /** @type import('@tanstack/react-table').ColumnDef<any>[] */
  const leaderBoardsColumns = [
    {
      accessorKey: "rank",
      header: "Rank",
    },
    {
      accessorKey: "teamname",
      header: "Team",
    },
    {
      accessorKey: "total_points",
      header: "Points Earned",
    },
  ];

  /** @type import('@tanstack/react-table').ColumnDef<any>[] */
  const playersColumns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "price",
      header: "Price",
      cell: ({ row }) => {
        const data = row.original;
        return <div>${data.price}</div>;
      },
    },
    {
      accessorKey: "roundscore",
      header: "Points Earned",
    },
  ];

  return (
    <div className="sectionlanding">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
          <img
            src={logoimages}
            alt="logo"
            style={{ width: 100, height: 100 }}
          />
        </div>
      ) : (
        <div className="contenorlanding">
          <div className="contentrow col-12">
            <div className="nevcolumn col-2">
              <div className="nevlogo">
                <img className="photoimagelanding" src={Logo1} alt="logo" />
              </div>
              <div className="nevlistst">
                <li className="listnevbars">
                  <Link
                    to="/players-list"
                    className="text-decoration-none text-black"
                  >
                    Player List
                  </Link>
                </li>

                <div
                  className="accordion accordion-flush"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        style={{ fontSize: "16px" }}
                        className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Tournament
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion show collapse px-2"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                        <Link
                          to="/tournament-list"
                          className="text-decoration-none text-black px-2"
                        >
                          <span style={{ fontSize: "14px" }}>List</span>
                        </Link>
                      </div>
                      <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                        <Link
                          to="/create-tournament"
                          className="text-decoration-none text-black text-sm px-2"
                        >
                          <span style={{ fontSize: "14px" }}>Create</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>
            </div>

            <div
              className="col-10 screensection"
              style={{ height: "auto", background: "#F8F8F8" }}
            >
              <div className="landingRow col-12 mb-4">
                <div className="nevbarbutton p-0">
                  <button
                    className="nevbarlogbutton m-0"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </button>
                </div>
              </div>

              <div className="landingsscreens bg-white p-2">
                <div className="landingscreen px-4">
                  <div className="d-flex flex-column align-items-center gap-3">
                    {/* Region Filter */}
                    <div className="w-100 d-flex flex-column gap-3 landingscreen">
                      <div className="text-center">
                        <Select
                          value={categoryOptions.find(
                            (option) => option.value === leagueCategory
                          )}
                          onChange={(e) => setLeagueCategory(e.value)}
                          options={categoryOptions}
                          placeholder="Select Region"
                        />
                      </div>
                    </div>

                    {/* Global Tournament Data */}
                    <div style={{ width: "100%" }}>
                      {/* <div className="card">
                        <div className="card-body">
                          <h3 className="fs-4">User Statistics</h3>
                          <h4 className="fs-6">User Id: {id}</h4>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">
                            Special title treatment
                          </h5>
                        </div>
                      </div> */}

                      <div
                        style={{ width: "100%" }}
                        className="d-flex flex-column gap-3"
                      >
                        {/* Global Tournaments Data */}
                        <div className="card d-flex flex-column align-items-md-start align-items-center px-3 py-2 gap-1">
                          <div className="d-flex flex-row gap-3 align-items-center">
                            <div>
                              <img
                                src={TrophyIcon}
                                height={20}
                                width={20}
                                alt="Trophy"
                              />
                            </div>
                            <div>
                              <span>Tournaments Participated</span>
                            </div>
                          </div>
                          <div>
                            <span
                              className="text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              Total Tournaments:{" "}
                              {globalData && globalData.length}
                            </span>
                          </div>
                          <div className="card-body" style={{ width: "100%" }}>
                            {/* Table */}
                            <BaseTable
                              columns={globalDataColumns}
                              data={globalData}
                            />
                          </div>
                        </div>

                        {/* Private Leagues Joined By User */}
                        <div className="card d-flex flex-column align-items-md-start align-items-center px-3 py-2 gap-1">
                          <div className="d-flex flex-row gap-3 align-items-center">
                            <div>
                              <img
                                src={JoinLeagueIcon}
                                height={20}
                                width={20}
                                alt="Join League"
                              />
                            </div>
                            <div>
                              <span>Leagues Created</span>
                            </div>
                          </div>
                          <div>
                            <span
                              className="text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              Total Tournaments:{" "}
                              {privateLeaguesOfUser &&
                                privateLeaguesOfUser.length}
                            </span>
                          </div>
                          <div className="card-body" style={{ width: "100%" }}>
                            {/* Table */}
                            <BaseTable
                              columns={privateLeagueOfUserColumns}
                              data={privateLeaguesOfUser}
                            />
                          </div>
                        </div>

                        <div className="card d-flex flex-column align-items-md-start align-items-center px-3 py-2 gap-1">
                          <div className="d-flex flex-row gap-3 align-items-center">
                            <div>
                              <img
                                src={JoinLeagueIcon}
                                height={20}
                                width={20}
                                alt="Join League"
                              />
                            </div>
                            <div>
                              <span>Leagues Joined</span>
                            </div>
                          </div>
                          <div>
                            <span
                              className="text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              Total Tournaments:{" "}
                              {privateLeaguesJoinedByUser &&
                                privateLeaguesJoinedByUser.length}
                            </span>
                          </div>
                          <div className="card-body" style={{ width: "100%" }}>
                            {/* Table */}
                            <BaseTable
                              columns={privateLeaguesJoinedColumns}
                              data={privateLeaguesJoinedByUser}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDialogOpen && (
        <div className="popup-overlay">
          <div
            className="popup-content"
            style={{ border: "1px solid #E5007D" }}
          >
            <div class="">
              <div class="modal-header d-flex justify-content-end rounded sticky-top text-end">
                <Clear
                  className="border rounded bg-danger text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsDialogOpen(false)}
                />
              </div>
              <div class="modal-body"></div>
              <div class="mt-4 d-flex flex-column justify-content-md-between align-items-md-center gap-3">
                {/* Leaderboards Table */}
                <div className="w-100">
                  <BaseTable
                    columns={leaderBoardsColumns}
                    data={leaderboards}
                    heading="Leaderboards"
                  />
                </div>

                {/* Player List */}
                <div className="w-100">
                  <BaseTable
                    columns={playersColumns}
                    data={players}
                    paginationRequired={false}
                    heading="Players"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default specificStats;
