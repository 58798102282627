import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    team: {}
}

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload
        }
    }
})

export const { setTeam } = teamSlice.actions;

// Export the reducer function directly from the slice
export default teamSlice.reducer;