import React, { useEffect, useState } from "react";
import logoimages from "../../images/logo_1.png";
import Logo1 from "../../images/Screenshot_1.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  getCoreRowModel,
  flexRender,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { getUserStats, userLogout } from "../../api";
import { Visibility } from "@material-ui/icons";
import BaseTable from "./data-table/BaseTable";
import { logoutUser } from "../../app/features/userSlice";
import { showToast } from "../toast";
import { useDispatch } from "react-redux";

function UserStats() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userStats, setUserStats] = useState([]);

  /** @type import('@tanstack/react-table').ColumnDef<any>[]*/
  const columns = [
    {
      accessorKey: "username",
      header: "Username",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "tournaments_participated",
      header: "Tournaments Participated",
    },
    {
      accessorKey: "private_leagues_created",
      header: "Private Leagues Created",
    },
    {
      accessorKey: "private_leagues_joined",
      header: "Private Leagues Joined",
    },
    {
      accessorKey: "regions",
      header: "Regions",
      cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.regions ? (
              <div className="d-flex flex-row gap-1">
                {data.regions.split(",").map((region, index) => (
                  <div
                    key={index}
                    className="badge"
                    style={{
                      border: "1px solid #E5007D",
                      color: "#E5007D",
                      background: "#fff",
                    }}
                  >
                    {region}
                  </div>
                ))}
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "participation_rate",
      header: "Participation Rate",

      cell: ({ row }) => {
        const data = row.original;
        return <div>{Number(data.participation_rate).toPrecision(3)} %</div>;
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div
            onClick={() =>
              history.push({
                pathname: "user-statistics",
                state: { id: row.original.id },
              })
            }
          >
            <Visibility
              style={{
                color: "#E5007D",
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");

  const table = useReactTable({
    data: userStats,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
  });

  useEffect(() => {
    async function fetchUserStats() {
      try {
        setLoading(true);
        const response = await getUserStats();
        if (response.status === 200) {
          setUserStats(response.data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchUserStats();
  }, []);

  const handleLogout = async () => {
    const response = await userLogout();

    if (response?.status === 200) {
      dispatch(logoutUser());
      showToast(response?.data?.message, response?.status);
      history.push({
        pathname: "/login",
      });
    }
  };

  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <div className="contenorlanding">
            <div className="contentrow col-12">
              <div className="nevcolumn col-2">
                <div className="nevlogo">
                  <img className="photoimagelanding" src={Logo1} alt="logo" />
                </div>
                <div className="nevlistst">
                  <li className="listnevbars">
                    <Link
                      to="/players-list"
                      className="text-decoration-none text-black"
                    >
                      Player List
                    </Link>
                  </li>

                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "16px" }}
                          className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Tournament
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion show collapse px-2"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/tournament-list"
                            className="text-decoration-none text-black px-2"
                          >
                            <span style={{ fontSize: "14px" }}>List</span>
                          </Link>
                        </div>
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/create-tournament"
                            className="text-decoration-none text-black text-sm px-2"
                          >
                            <span style={{ fontSize: "14px" }}>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>
              <div
                className="col-10 screensection"
                style={{ height: "auto", background: "#F8F8F8" }}
              >
                <div className="landingRow col-12 mb-4">
                  <div className="nevbarbutton p-0">
                    <button
                      className="nevbarlogbutton m-0"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className="landingsscreens bg-white p-2">
                  <div className="landingscreen px-4">
                    {/* Table */}
                    <BaseTable
                      data={userStats}
                      columns={columns}
                      paginationRequired={true}
                      searchRequired={true}
                      sortingRequired={true}
                      heading="User Statistics"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserStats;
