import {
  ArrowDropDown,
  ArrowDropUp,
  Search,
  Visibility,
} from "@material-ui/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import Pagination from "./Pagination";
import SortIcon from "../../../assets/sort.png";

const BaseTable = ({
  columns,
  data = [],
  paginationRequired = true,
  searchRequired = true,
  sortingRequired = true,
  heading = "",
  searchPlaceholder = "",
}) => {
  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
  });
  return (
    <>
      <div>
        {searchRequired && (
          <div className="d-flex flex-md-row flex-column justify-content-md-between justify-content-center align-items-center">
            <div className="flex-1">
              <h4>{heading || ""}</h4>
            </div>
            <div
              class="input-group mb-3 w-fit-content"
              style={{ maxWidth: "300px" }}
            >
              <input
                type="text"
                class="form-control"
                placeholder={searchPlaceholder || "Search"}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              <span class="input-group-text" id="basic-addon2">
                <Search />
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="overflow-x-scroll">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ cursor: "pointer" }}
                    onClick={
                      sortingRequired && header.column.getToggleSortingHandler()
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {sortingRequired &&
                      (header.column.getIsSorted() === "asc" ? (
                        <ArrowDropUp />
                      ) : header.column.getIsSorted() === "desc" ? (
                        <ArrowDropDown />
                      ) : (
                        <img
                          alt="sort"
                          src={SortIcon}
                          width={10}
                          height={10}
                          className="opacity-25 pl-1"
                        />
                      ))}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={columns.length}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>{paginationRequired && <Pagination table={table} />}</div>
    </>
  );
};

export default BaseTable;
