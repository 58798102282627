import React, { useState } from "react";
import "./Style.css";
import BackgroundImage from "../../images/Left.png";
import logoimages from "../../images/logo_1.png";
import { useHistory } from "react-router-dom";
import Visibility from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOutlined";
import { useEffect } from "react";
import { showToast } from "../toast";
import { login } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../app/features/userSlice";

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (
      isAuthenticated &&
      localStorage.getItem("AdminPrevPath") !== "" &&
      localStorage.getItem("AdminPrevPath") !== null &&
      localStorage.getItem("AdminPrevPath") !== undefined
    ) {
      history.push({
        pathname: localStorage.getItem("AdminPrevPath"),
      });
    }
  }, [isAuthenticated]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleFormChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleErrors = async () => {
    let tempErrors = {};

    if (formData.email.trim().length === 0) {
      tempErrors.email = "Please Enter Email Address";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      tempErrors.email = "Please Enter Valid Email Address";
    }

    if (formData.password.trim().length === 0) {
      tempErrors.password = "Please Enter Password";
    } else if (formData.password.trim().length < 8) {
      tempErrors.password = "Minimum 8 characters required";
    }

    setErrors(tempErrors);
    // tempErrors = {};

    if (Object.keys(tempErrors).length === 0) {
      if (true) {
        localStorage.username = formData.email;
        localStorage.email = formData.email;
        localStorage.password = "";
        localStorage.checkbox = formData.rememberMe;
      }

      const response = await login(formData);

      if (response.status === 200) {
        showToast(response.data.message, response.status);
        dispatch(loginUser(response.data.user));
        return history.push({
          pathname: "/tournament-list",
        });
      }

      if (response.status === 400) {
        if (response.data.additionalData.invalidCredentials) {
          return setErrors((prev) => ({
            ...prev,
            password: response.data.message,
          }));
        }

        if (!response.data.additionalData.userExists) {
          return showToast(response.data.message, response.status);
        }
      } else {
        showToast("Something went erong", 500);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== "") {
      document.getElementById("checkbox").checked = true;
      setEmail(localStorage.username);
      setPassword(localStorage.password);
    }
  }, []);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const passwordhandleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="section">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
          <img
            src={logoimages}
            style={{ width: 100, height: 100 }}
            alt="logo"
          />
        </div>
      ) : (
        <>
          <div className="Row" style={{ overflowY: "hidden" }}>
            <div className="col1">
              <img
                style={{ maxHeight: "100vh" }}
                src={BackgroundImage}
                className="mainimg"
                alt="backImage"
              />
            </div>
            <div className="col">
              <div className="contener">
                <div className="d-box">
                  <img src={logoimages} height={100} width={150} alt="logo" />
                  <h3 className="hading mt-3 h-50 mb-3">
                    Welcome Back to Clutchup
                  </h3>
                  <div className="form-outline">
                    <div className="user-input-wrp">
                      <br />
                      <input
                        // onChange={handleUserName}
                        // value={email}
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                        value={formData.email}
                        id="email"
                        type="text"
                        className="inputText"
                        required
                      />
                      <span className="floating-label">EMAIL</span>

                      {errors && errors.email ? (
                        <small className="d-block mb-2 red errors">
                          {errors.email}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="user-input-wrp">
                      <br />
                      <input
                        onChange={(e) =>
                          handleFormChange("password", e.target.value)
                        }
                        value={formData.password}
                        type={values.showPassword ? "text" : "password"}
                        className="inputText"
                        id="password"
                        maxLength={30}
                        required
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="20"
                        color="#757575"
                        className="bi bi-eye"
                        onClick={passwordhandleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-hidden="true"
                        viewBox="0 0 16 16"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </svg>
                      <span className="floating-label">PASSWORD</span>
                      {errors && errors.password ? (
                        <small className="d-block mb-2 red errors">
                          {errors.password}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="footer-links">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="fruit-1"
                      onChange={(e) =>
                        handleFormChange("rememberMe", e.target.checked)
                      }
                    />
                    <label htmlFor="checkbox"> &nbsp;Remember Me </label>
                  </div>

                  <div className="btn2">
                    <button
                      className="button"
                      type="submit"
                      name="submit"
                      value="Submit"
                      onClick={handleErrors}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
